import { Form } from "react-router-dom";
import { TextInput } from "./inputs";
import { User } from "../models/user";

export default function UserForm(props: { user: User, onCancel: () => void }) {
    const user = props.user;

    return (
        <Form method='post'>
            <div className='m-2 flex justify-between'> 
                <h1 className='text-3xl'>New User</h1>
                <div className='flex'>
                    <button type="submit" className='mr-4 save-btn'>Save</button>
                    <button type="button" className='edit-btn' onClick={() => props.onCancel()}>
                        Cancel
                    </button>
                </div>
            </div>
            <div className='pt-4'>
                <TextInput name='name' value={user.name} />
                <TextInput name='email' type='email' value={user.email} />
            </div>
        </Form>
    )
}