import { FC, useState } from 'react'
import { UserCollectionItem } from '../models/user';
import { UserDisplayFields } from './Users';
import { Table, ToggleSwitch } from 'flowbite-react';
import { DateInput } from './inputs';
import { Form, useLoaderData, useRevalidator } from 'react-router-dom';
import { Role } from '../models/role';
import toast from 'react-hot-toast';
import { updateItem } from '../helpers/api-utils';
import { Confirmation, useConfirmation } from '../Confirm';

interface ActiveUserRowProps {
  user: UserCollectionItem & UserDisplayFields;
  isEditing?: boolean;
  isUpdating?: boolean;
  onSetEditUserId: (id: number) => void;
  onSetIsUpdating: (isUpdating: boolean) => void;
};

const ActiveUserRow: FC<ActiveUserRowProps> = ({ user, isEditing, isUpdating, onSetEditUserId, onSetIsUpdating }) => {
  const { roles } = useLoaderData() as { roles: Role[] };

  const { confirm } = useConfirmation();
  const revalidator = useRevalidator();

  const [memberSince, setMemberSince] = useState<Date | null>(user.memberSince ? new Date(user.memberSince) : null);

  const updateMemberSince = async () => {
    onSetIsUpdating(true);

    const result = await updateItem({ url: `users/${user.id}`, changes: { memberSince } });

    if (result.success) toast.success('Member since saved');
    else toast.error(result.message || 'Failed to update member since');

    onSetIsUpdating(false);
    revalidator.revalidate();
  }

  const updateUserRole = async (user: UserCollectionItem, role: Role, isSelected: boolean) => {
    onSetIsUpdating(true);

    const result = await updateItem({ url: `users/${user.id}/roles`, changes: { roleId: role.id, isSelected } });

    if (result.success) toast.success('User role saved');
    else toast.error(result.message || 'Failed to update user role');

    onSetIsUpdating(false);
    revalidator.revalidate();
  };

  const roleToggles = (user: UserCollectionItem) => roles.map(role => {
    const hasRole = user.roles.some(ur => ur.role.code === role.code);
    return (
        <li key={role.id}>
            <ToggleSwitch checked={hasRole} disabled={isUpdating} label={role.description} onChange={checked => updateUserRole(user, role, checked)} />
        </li>
    );
  });

  return (
    <>
      <Confirmation />
      <Table.Row key={user.id}>
        {isEditing ? (
          <Table.Cell colSpan={6} className="border">
            <div className="flex justify-between">
                <span className="text-lg">{user.name}</span>
                <button type="button" className="edit-btn" onClick={() => onSetEditUserId(0)}>
                    Close
                </button>
            </div>

            <div className='flex-col mt-4 mb-8'>
              <span className="text-md">Change Member Since</span>

              <div className="flex">
                <DateInput
                  name='Member Since'
                  value={memberSince?.toString() || ''}
                  onChange={e => {
                    const updatedMemberSince = new Date(e.target.value);

                    setMemberSince(updatedMemberSince.toLocaleString() === 'Invalid Date' ? new Date(user.memberSince) : updatedMemberSince);
                  }}
                />

                <button type="button" className="w-20 h-12 ml-4 mt-auto add-btn" onClick={updateMemberSince}>
                    Save
                </button>
              </div>
            </div>

            <div>
                <span className="text-md">Assigned Roles</span>
                <ul className="p-3 space-y-1 text-sm text-gray-700" aria-labelledby="dropdownToggleButton">
                    {roleToggles(user)}
                </ul>
            </div>
            <div className="flex justify-end">
                <Form
                    method="post"
                    action={`${user.id}/delete`}
                    onSubmit={event => confirm(event, { action: 'Delete User', message: 'Are you sure you want to delete this user?' })}
                >
                    <button type="submit" className='w-64 mr-4 delete-btn'>
                        Permanently Delete User
                    </button>
                </Form>
                <Form
                    method="post"
                    action={`${user.id}/archive`}
                    onSubmit={event => confirm(event, { action: 'Archive User', message: 'Are you sure you want to archive this user?' })}
                >
                    <button type="submit" className='w-48 add-btn'>
                        Archive User
                    </button>
                </Form>
            </div>
          </Table.Cell>
          ) : (
            <>
              <Table.Cell>{user.isAdmin.toString()}</Table.Cell>
              <Table.Cell>{user.subscriptionTier}</Table.Cell>
              <Table.Cell>{user.sendJournalReminders.toString()}</Table.Cell>
              <Table.Cell>{user.name}</Table.Cell>
              <Table.Cell>{user.email}</Table.Cell>
              <Table.Cell>{memberSince?.toLocaleDateString()}</Table.Cell>
              <Table.Cell>
                  <button type="button" disabled={isUpdating} className="edit-btn" onClick={() => onSetEditUserId(user.id ?? 0)}>
                      Edit
                  </button>
              </Table.Cell>
            </>
          )
        }
      </Table.Row>
    </>
  );
}

export default ActiveUserRow