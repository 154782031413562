import * as React from "react";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../providers/AuthProvider";
import { toast } from "react-hot-toast";

export default function Login() {
    const { login, logout } = React.useContext(AuthContext);
    const navigate = useNavigate();

    const formSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.target as HTMLFormElement);
        const email = formData.get('email')?.toString(),
            password = formData.get('password')?.toString();
        if (email && password) {
            const result = await login({ email, password });
            if (result) {
                if (result.roles.some(r => r.role.code === 'ADMIN')) {
                    navigate('/');
                } else {
                    logout();
                    toast.error('Your account is not authorized to use this portal. Please contact your administrator if you believe this is an error.');
                }
            } else {
                toast.error('Invalid email/password');
            }
        }
    }

    return (
        <section className="bg-white">
            <div className="lg:grid lg:min-h-screen lg:grid-cols-12">
                <main aria-label="Main"
                    className="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:col-start-3 lg:px-16 lg:py-12 xl:col-span-6 xl:col-start-4"
                >
                    <div className="flex flex-col items-center max-w-xl lg:max-w-3xl">
                        <a className="block text-medBlue" href="/">
                            <span className="sr-only">Home</span>
                            <img alt="Logo" src={`${process.env.PUBLIC_URL}/images/TMPx-Logo-Revised-blue-gold.png`} />
                        </a>

                        <h1 className="mt-6 text-center text-xl font-bold text-gray-900 sm:text-2xl md:text-3xl">
                            Welcome to the TMPx Admin Portal
                        </h1>

                        <form onSubmit={formSubmit} className="mt-8 grid grid-cols-6 gap-6 w-80">
                            <div className="col-span-6">
                                <label htmlFor="Email" className="block text-sm font-medium text-gray-700">
                                    Email
                                </label>

                                <input
                                type="email"
                                id="Email"
                                name="email"
                                className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                                />
                            </div>

                            <div className="col-span-6">
                                <label
                                htmlFor="Password"
                                className="block text-sm font-medium text-gray-700"
                                >
                                Password
                                </label>

                                <input
                                type="password"
                                id="Password"
                                name="password"
                                className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                                />
                            </div>

                            <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
                                <button type="submit"
                                    className="inline-block shrink-0 rounded-md border border-medBlue bg-medBlue px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-medBlue focus:outline-none focus:ring active:text-medBlue"
                                >
                                    Log In
                                </button>
                            </div>
                        </form>
                    </div>
                </main>
            </div>
        </section>
    );
};