import { useState } from 'react';
import { toast } from 'react-hot-toast'
import { Form } from 'react-router-dom';
import { attachFile } from '../helpers/api-utils';
import { TextInput } from './inputs';
import { FileInput } from 'flowbite-react';
import { Confirmation, useConfirmation } from '../Confirm';
import { Document as DocumentModel } from '../models/document';
import { Link } from 'react-router-dom';

export function documentFormFields(doc: DocumentModel) {
    return (
        <>
            <TextInput name='name' value={doc.name} />
            <TextInput name='type' value={doc.type} />
            <input type="hidden" name='code' value={doc.code} />
            <input type='hidden' name='path' value={doc.path || ''} />
        </>
    )
}

export default function Document(props: { document: DocumentModel }) {
    const [doc, setDoc] = useState(props.document);
    const { confirm } = useConfirmation();

    const upload = async (e: any) => {
        const file = e.target.files[0];
        const result = await attachFile('documents', doc.id, file);
        
        if (result.success) {
            const model = {
                ...doc,
                path: result.data.path,
                fileId: result.data.id
            };
            toast.success('Document saved');
            setDoc(model);
        } else {
            toast.error(result.message || 'error');
        }
    };

    const deleteFormId = `mod-${doc.id}-del-from`;

    return (
        <>
            <Confirmation />
            <div className='flex items-center justify-between'>
                <h2 className='text-xl'>{doc.name}</h2>
                <Link to={`/notifications?sessionId=${doc.sessionId}&documentId=${doc.id}`} className='add-btn'>Notify Users About This Document</Link>
            </div>
            <Form method='post' action={`documents/${doc.id}/update`}>
                {documentFormFields(doc)}
                <FileInput
                    className='mt-4'
                    multiple={false}
                    name="tempPath"
                    onChange={upload}
                    helperText={doc.path}
                />
                <div className='pt-4 flex justify-end'>
                    <button form={deleteFormId} type="submit" className='w-48 mr-4 delete-btn'>
                        Delete Document
                    </button>
                    <button type="submit" className='w-48 save-btn'>
                        Save Document
                    </button>
                </div>
            </Form>
            <Form
                id={deleteFormId}
                className='my-4'
                method="post"
                action={`documents/${doc.id}/destroy`}
                onSubmit={event => confirm(event, { action: 'Delete Document', message: 'Are you sure you want to delete this document?' })}>
            </Form>
        </>
    )
}