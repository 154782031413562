import * as React from "react";
import { NavLink, redirect } from 'react-router-dom';
import { User } from "../models/user";
import { AuthContext } from "../providers/AuthProvider";
import { ArrowIcon, ChecklistIcon, ExitIcon, GroupIcon, PersonIcon, ProfileIcon, UploadIcon } from "./icons";

export default function SideNav() {
    const { profile, logout } = React.useContext(AuthContext);
    const [user, setUser] = React.useState<User>();

    React.useEffect(() => {
        const getProfile = async () => {
            const prof = await profile();
            setUser(prof);
        }
        getProfile();
    }, [profile]);

    const logoutAndReturnToRoot = () => {
        logout();
        redirect('/');
    };

    return (
        <div className="flex h-screen flex-col items-center justify-between border-e bg-darkBlue min-w-fit lg:w-1/5">
            <div className="px-4 py-6">
                <div className="h-10 flex flex-col items-center text-white">
                    <img alt="logo" width={128} height={33} src={`${process.env.PUBLIC_URL}/images/logo-white.png`} />
                    Back-End Portal
                </div>

                <nav aria-label="Main Nav" className="mt-6 flex flex-col space-y-1">
                    <NavLink to="/sessions" className="nav-button">
                        <ChecklistIcon />
                        <span className="text-sm font-medium"> Sessions </span>
                    </NavLink>

                    <NavLink to="/users" className="nav-button">
                        <GroupIcon />
                        <span className="text-sm font-medium"> Users </span>
                    </NavLink>

                    <NavLink to="/files" className="nav-button">
                        <UploadIcon />
                        <span className="text-sm font-medium"> Files </span>
                    </NavLink>

                    <details>
                        <summary className="nav-link">
                            <div className="flex items-center justify-center flex-1 gap-2">
                                <PersonIcon />
                                <span className="text-sm font-medium"> Account </span>
                            </div>

                            <span className="shrink-0 transition duration-300 group-open:-rotate-180 text-gold">
                                <ArrowIcon />
                            </span>
                        </summary>

                        <nav aria-label="Account Nav" className="mt-2 flex flex-col px-4">
                            <NavLink to="/profile" className="nav-link">
                                <ProfileIcon />
                                <span className="text-sm font-medium"> Profile </span>
                            </NavLink>

                            <button type="button" className="nav-link" onClick={logoutAndReturnToRoot}>
                                <ExitIcon />
                                <span className="text-sm font-medium"> Logout </span>
                            </button>
                        </nav>
                    </details>
                </nav>
            </div>

            <div className="sticky inset-x-0 bottom-0">
                <div className="flex items-center gap-2 p-4 text-white">
                    <p className="text-xs">
                        <strong className="block font-medium">{user?.name}</strong>
                        <span>{user?.email}</span>
                    </p>
                </div>
            </div>
        </div>
    );
}