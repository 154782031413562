export function capitalize(target: string): string {
    switch (target) {
        case undefined:
            throw new Error('Undefined value');
        case null:
        case '':
            return target;
        // case string s when s[0].IsNumeric():
        //         int ix = 1;
        //         while (ix < s.Length && s[ix].IsNumeric()) ix++;
        //         string start = s.Substring(0, ix), end = s.Substring(ix);
        //         string[] suffixes = { "st", "nd", "rd", "th", "ST", "ND", "RD", "TH" };
        //         if (Array.IndexOf(suffixes, end) >= 0)
        //             return start + end.ToLower();
        //         return start + end.Capitalize();
        default:
            return target[0].toLocaleUpperCase() + target.substring(1).toLocaleLowerCase();
    }
}