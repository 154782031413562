import { ActionFunctionArgs, redirect } from "react-router-dom";
import { Module } from "../models/module";
import invariant from "tiny-invariant";
import { createItem, deleteItem, updateItem } from "../helpers/api-utils";
import { toast } from "react-hot-toast";

export function createModule() {
    const module = {
        // id: null,
        thumbnailUrl: '',
        name: '',
        description: '',
        tab: '',
        code: '',
        videoUrl: '',
        time: '',
        // dueDate: undefined,
    } as Module;
    return module;
}

export async function destroyModuleAction(args: ActionFunctionArgs) {
    invariant(args.params.sessionId, 'Session ID is required');
    invariant(args.params.moduleId, 'Module ID is required');
    
    const sessionId = Number(args.params.sessionId);
    const moduleId = Number(args.params.moduleId);
    const result = await deleteItem('modules', moduleId);

    result.success ?
        toast.success('Module deleted') :
        toast.error(result.message || 'Failed to delete');

    return redirect(`/sessions/${sessionId}`);
}

export async function updateModuleAction(args: ActionFunctionArgs) {
    invariant(args.params.sessionId, 'Session ID is required');
    
    const formData = await args.request.formData();
    const sessionId = Number(args.params.sessionId);
    const moduleId = args.params.moduleId;
    const model = {
        ...Object.fromEntries(formData),
        sessionId
    } as Module;

    const isNew = moduleId === undefined;
    const result = isNew ?
        await createItem('modules', model) :
        await updateItem({ resource: 'modules', id: Number(moduleId), changes: model });

    if (result.success) {
        isNew ? 
            toast.success('Created new module') :
            toast.success('Module saved');
    } else {
        toast.error(result.message || 'Failed to save');
    }
    
    return redirect(`/sessions/${sessionId}`);
}

