import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { ProgressCallback, fetchProtectedData } from "./http-utils";
import { Upload } from "@aws-sdk/lib-storage";
import * as mime from 'mime';

export type ApiResult = { success: boolean, message?: string, data?: any }
export type ApiCollection<T> = { data: T[], count: number }

const resourceToUrl = (resource: string) => resource.startsWith('/') ? resource : '/' + resource;

export async function createItem(resource: string, item: any): Promise<ApiResult> {
  const url = resourceToUrl(resource);
  const { statusCode, data } = await fetchProtectedData({ url, method: 'POST', body: item });

  if (statusCode === 201) {
    return { success: true, data };
  }

  return { success: false, message: data.message?.message || `Create failed - ${statusCode}` };
}

export async function deleteItem(resource: string, id: number): Promise<ApiResult> {
  const url = `${resourceToUrl(resource)}/${id}`;
  const { statusCode, data } = await fetchProtectedData({ url, method: 'DELETE' });

  if (statusCode === 200) {
    return { success: true };
  }

  return { success: false, message: data.message?.message || `Delete failed - ${statusCode}` };
}

export async function getCollection(url: string, params?: any) {
  const { statusCode, data } = await fetchProtectedData({ url, params });

  if (statusCode === 200) {
    return data.data;
  }

  throw new Error(`Failed to fetch ${url} - ${statusCode}`);
}

export async function getItem(args: { resource?: string, id?: number, url?: string }) {
  const url = resourceToUrl(args.url ?? `${args.resource}/${args.id}`);
  const { statusCode, data } = await fetchProtectedData({ url });

  if (statusCode === 200) {
    return data;
  }

  throw new Error(`Failed to fetch ${url} - ${statusCode}`);
}

export async function updateItem(args: { resource?: string, id?: number, url?: string, changes: any }): Promise<ApiResult> {
  const url = resourceToUrl(args.url ?? `${args.resource || ''}/${args.id}`);
  const { statusCode, data } = await fetchProtectedData({ url, method: 'PATCH', body: args.changes });

  if (statusCode === 200) {
    return { success: true, data };
  }

  return { success: false, message: data.message?.message || `Update failed - ${statusCode}` };
}

export async function attachFile(resource: string, id: number, file: any): Promise<ApiResult> {
  const url = `${resourceToUrl(resource)}/${id}/file`;
  const { statusCode, data } = await fetchProtectedData({ url, method: 'POST', body: file, isFormData: true });

  if (statusCode === 201) {
    return { success: true, data };
  }

  return { success: false, message: data.message || `Upload failed - ${statusCode}` };
}

export async function uploadFile(folder: string, file: File, progressCallback?: ProgressCallback): Promise<ApiResult> {
  const { statusCode, data } = await fetchProtectedData({ method: 'GET', url: '/files/upload-credentials' });

  if (statusCode !== 200) {
    return { success: false, message: 'Failed to get upload credentials.' };
  }

  const { accessKeyId,
    secretAccessKey,
    sessionToken,
    region,
    bucket,
  } = data;
  const client = new S3Client({
    region,
    credentials: { accessKeyId, secretAccessKey, sessionToken },
  });
  const params = {
    Bucket: bucket,
    Body: file,
    Key: `${folder}/${file.name}`,
    //@ts-ignore
    ContentType: mime.getType(file.name, 'text/plain') || undefined,
    ACL: 'public-read'
  };

  const tenMb = 1024 * 1024 * 10;
  if (file.size > tenMb) {
    const parallelUpload = new Upload({
      client,
      params,
      queueSize: 4, // optional concurrency configuration
      partSize: tenMb, // optional size of each part, in bytes, at least 5MB
      leavePartsOnError: false, // optional manually handle dropped parts
    });

    parallelUpload.on('httpUploadProgress', progress => {
      console.log('Uploading', file.name, progress);
      if (progressCallback) {
        progressCallback((progress.loaded || 0) * 100 / (progress.total || 1));
      }
    });

    //IMPORTANT - this will fail unless the ETag header is exposed in the bucket; this can be configured in the S3 bucket permissions
    await parallelUpload.done();
  } else {
    await client.send(new PutObjectCommand(params));
    if (progressCallback) {
      progressCallback(100);
    }
  }

  const fileStorageInDB = {
    key: params.Key,
    url: `https://${params.Bucket}.s3.${region}.amazonaws.com/${params.Key}`,
  };

  return await createItem('files', fileStorageInDB);
}
