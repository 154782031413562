import { ActionFunctionArgs, redirect } from "react-router-dom";
import invariant from "tiny-invariant";
import { createItem, deleteItem, updateItem } from "../helpers/api-utils";
import { toast } from "react-hot-toast";
import { Document } from "../models/document";

export function createDocument() {
    const document = {
        name: '',
        type: '',
        code: '',
        path: '',  
    } as Document;
    return document;
}

export async function destroyDocumentAction(args: ActionFunctionArgs) {
    invariant(args.params.sessionId, 'Session ID is required');
    invariant(args.params.documentId, 'Module ID is required');
    
    const sessionId = Number(args.params.sessionId);
    const docId = Number(args.params.documentId);
    const result = await deleteItem('documents', docId);
    
    result.success ?
        toast.success('Document deleted') :
        toast.error(result.message || 'Failed to delete');

    return redirect(`/sessions/${sessionId}`);
}

export async function updateDocumentAction(args: ActionFunctionArgs) {
    invariant(args.params.sessionId, 'Session ID is required');
    
    const formData = await args.request.formData();
    const sessionId = Number(args.params.sessionId);
    const documentId = args.params.documentId;
    const model = {
        ...Object.fromEntries(formData),
        sessionId
    } as Document;

    const isNew = args.params.documentId === undefined;
    const result = isNew ?
        await createItem('documents', model) :
        await updateItem({ resource: 'documents', id: Number(documentId), changes: model });

    if (result.success) {
        isNew ? 
            toast.success('Created new document') :
            toast.success('Document saved');
    } else {
        toast.error(result.message || 'Failed to save');
    }
        
    return redirect(`/sessions/${sessionId}`);
}