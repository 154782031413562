import React from "react";
import { useRouteError } from "react-router-dom";

type ErrorState = {
    hasError: boolean;
    error: any;
}

export class ErrorBoundary extends React.Component {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false, error: null } as ErrorState;
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true, error };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.error(error);
    }

    render(): React.ReactNode {
        const state = this.state as ErrorState;
        
        if (state.hasError) {
            return <ErrorMessage message={state.error.message} />;
        }

        return (this.props as any).children;
    }
}

export function ErrorHandler() {
    const error = useRouteError() as any;
    return <ErrorMessage message={error.message} />;
}

function ErrorMessage(props: any) {
    return (
        <div className="full-screen-center flex-col">
            <p className="text-2xl">Oops! Something has gone wrong</p>
            <p>{props.message}</p>
        </div>
    );
}