import { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { faCaretLeft, faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type HasIdAndName = { id: number, name: string };

function AccordionPanel(props: { index: number, item: HasIdAndName, children: () => JSX.Element }) {
    const { index, item } = props;
    const id = item.id.toString();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Draggable draggableId={id} index={index}>
        {provided =>
            <div 
                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                className="first:rounded-t-lg last:rounded-b-lg bg-darkBlue hover:ring-1 hover:ring-gray-500 border border-b-0 border-gray-800"
            >
                <h2
                    className="flex w-full items-center justify-between py-5 px-5"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <div>
                        <FontAwesomeIcon icon={faGripVertical} className="text-medBlue" />
                        <span className="ml-16 font-medium text-white">{item.name}</span>
                    </div>
                    <FontAwesomeIcon icon={faCaretLeft} className={"text-gold w-6 h-6 " + (isOpen ? '-rotate-90' : '')} />
                </h2>
                <div className={ isOpen ? '' : 'hidden' }>
                    <div className="p-4 bg-white">
                        {props.children()}
                    </div>
                </div>
            </div>
        }
        </Draggable>
    )
}

export default function Accordion(props: { collection: HasIdAndName[], onRearrange: (order: number[]) => void, renderContent: (item: HasIdAndName) => JSX.Element }) {
    // const [collection, setCollection] = useState(props.collection);
    
    const panels = props.collection.map((item, index) => (
        <AccordionPanel key={item.id} index={index} item={item} children={() => props.renderContent(item)} />
    ));

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }

        const [destIx, sourceIx] = [result.destination.index, result.source.index];
    
        if (destIx === sourceIx) {
            return;
        }

        const items = props.collection;
        const [removed] = items.splice(sourceIx, 1);
        items.splice(destIx, 0, removed);

        // setCollection(items);

        const order = items.map(i => i.id);
        props.onRearrange(order);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='accordion'>
            {provided =>
                <div ref={provided.innerRef} {...provided.droppableProps}>
                    {panels}
                    {provided.placeholder}
                </div>
            }
            </Droppable>
        </DragDropContext>
    );
}