import { toast } from "react-hot-toast";
import { deleteItem, updateItem } from "../helpers/api-utils";
import { redirect } from "react-router-dom";
import { User } from "../models/user";
import { fetchProtectedData } from "../helpers/http-utils";
import { requireToken } from "../hooks/use-auth";

export async function archiveUserAction(args: any) {
    const userId = args.params.userId;
    const result = await updateItem({ resource: 'users', id: userId, changes: { isActive: false } });

    result.success ?
        toast.success('User archived') :
        toast.error(result.message || 'Failed to archive');

    return redirect('/users');
}

export function createUser(): User {
    return {
        id: null,
        name: '',
        email: '',
        sendJournalReminders: true,
        memberSince: new Date(),
        updatedAt: new Date(),
    };
}

export async function createUserAction(args: any) {
    const formData = await args.request.formData();
    const model = Object.fromEntries(formData);
    const { statusCode, data } = await fetchProtectedData({ url: '/auth/create-user', method: 'POST', body: model });

    if (statusCode === 201) {
        toast.success('Created new user');
        return redirect('/users');
    }

    toast.error(data.message?.message || 'Failed to save');
    return redirect('/users/new');
}

export async function deleteUserAction(args: any) {
    const userId = args.params.userId;
    const result = await deleteItem('users', userId);

    result.success ?
        toast.success('User deleted') :
        toast.error(result.message || 'Failed to delete');

    return redirect('/users');
}

export async function restoreUserAction(args: any) {
    const userId = args.params.userId;
    const result = await updateItem({ resource: 'users', id: userId, changes: { isActive: true } });

    result.success ?
        toast.success('User restored') :
        toast.error(result.message || 'Failed to restore');

    return redirect('/users');
}
