import * as React from 'react';
import { Outlet, Navigate } from "react-router-dom";
import SideNav from "./SideNav";
import { AuthContext } from "../providers/AuthProvider";

export default function Home() {
    const { token } = React.useContext(AuthContext);

    return (
        token ?
            <div className="flex">
                <SideNav />
                <div className="flex-1">
                    <Outlet />
                </div>
            </div> :
            <Navigate to="/login" />
    );
}