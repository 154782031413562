import { MouseEvent, useState } from "react";
import { TextInput } from "./inputs";
import { createItem, getItem } from "../helpers/api-utils";
import { LoaderFunctionArgs, useLoaderData, useNavigate } from "react-router-dom";
import { Notification } from "../models/notification";
import { Confirmation, useConfirmation } from "../Confirm";
import { toast } from "react-hot-toast";

export async function notificationLoader(args: LoaderFunctionArgs) {
    const url = new URL(args.request.url);
    const params = Array.from(url.searchParams).map(([k, v]) => {
        if (k.endsWith('Id')) {
            return [k, Number(v)];
        }
        return [k, v];
    });
    const { sessionId, moduleId, documentId, journalReminder} = Object.fromEntries(params);
    
    let type = 'General - will not be linked to specific content',
        title = 'New Push Notification',
        content = 'Enter your notification content here...';

    if (sessionId) {
        title = 'New Lesson Available Now';

        if (moduleId) {
            type = 'Module - will direct users to the video';
            const module = await getItem({ resource: 'modules', id: Number(moduleId) });
            content = module.name;
        } else if (documentId) {
            type = 'Document - will direct users to the PDF';
            const doc = await getItem({ resource: 'documents', id: Number(documentId) });
            content = doc.name;
        } else {
            type = 'Session - will direct users to the session';
            const session = await getItem({ resource: 'sessions', id: Number(sessionId) });
            content = session.description;
        }
    } else if (journalReminder) {
        type = 'Journal reminder - will direct users to their journal';
        title = 'Daily Journal';
        content = 'Have you logged your journal entries for today?';
    }

    return { type, title, content, sessionId, moduleId, documentId, journalReminder };
}

export default function Notifications() {
    const data = useLoaderData() as Notification;
    const [notification, setNotification] = useState(data);
    const { confirmNow } = useConfirmation();
    const navigate = useNavigate();

    const sendNotification = async () => {
        const result = await createItem('notifications', notification);
        
        if (result.success) {
            toast.success('Successfully sent push notification');
        } else {
            toast.error(result.message || 'Failed to send push notification');
        }
    
        return navigate(-1);
    }

    const handleSubmit = (event: MouseEvent) => {
        confirmNow(
            { action: 'Send Push Notification', message: 'Are you sure you want to send this notification to all subscribed users?' },
            async () => await sendNotification());
    }
    
    return (
        <div className="flex h-full">
            <Confirmation />
            <div className="p-4 flex-1">
                <p className="my-4 text-sm"><span className="font-bold mr-2">Type:</span>{notification.type}</p>
                <TextInput
                    name="title"
                    placeholder="title"
                    value={notification.title}
                    onChange={(e: any) => setNotification({ ...notification, title: e.target.value })}
                />
                <TextInput
                    name="content"
                    placeholder="content"
                    value={notification.content}
                    onChange={(e: any) => setNotification({ ...notification, content: e.target.value })}
                />
                <input type="hidden" name="sessionId" value={notification.sessionId} />
                <input type="hidden" name="moduleId" value={notification.moduleId} />
                <input type="hidden" name="documentId" value={notification.documentId} />
                <input type="hidden" name="journalReminder" value={(notification.journalReminder ?? false).toString()} />
                <button type="button" className="save-btn mt-4" onClick={handleSubmit}>
                    Send Push Notification
                </button>
            </div>
            <div className="p-4 flex-1 flex flex-col">
                <p className="font-xl font-bold text-center">Preview</p>
                <div className="mt-4 flex-1 flex items-center justify-center bg-gray-300 rounded-lg shadow-inner">
                    <div className="p-4 bg-white rounded-md w-72">
                        <p className="font-bold">TMPx App</p>
                        <p className="font-bold">{notification.title}</p>
                        <p>{notification.content}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}