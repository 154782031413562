import './App.css';
import { createBrowserRouter, Navigate, RouterProvider, redirect } from 'react-router-dom';
import Login from './components/Login';
import Home from './components/Home';
import Sessions, { loader as sessionsLoader } from './components/Sessions';
import Session, {
    sessionLoader,
} from './components/Session';
import SessionForm from './components/SessionForm';
import AuthProvider from './providers/AuthProvider';
import { isLoggedIn } from './hooks/use-auth';
import Alert from './components/Alert';
import Users, { userLoader } from './components/Users';
import Files, { fileLoader } from './components/Files';
import useCustomTheme from './flowbite-theme';
import { ErrorBoundary, ErrorHandler } from './ErrorBoundary';
import { Profile, profileLoader } from './components/Profile';
import UserForm from './components/UserForm';
import {
    archiveUserAction, createSession, createUser, createUserAction, deleteUserAction,
    destroyDocumentAction, destroyFileAction, destroyModuleAction, destroySessionAction, restoreUserAction, 
    updateDocumentAction, updateModuleAction, updateSessionAction
} from './actions';
import Notifications, { notificationLoader } from './components/Notifications';
import MobileRequired from './components/MobileRequired';

export async function sessionIdLoader(args: any) {
    const sessionId = args.params.sessionId;
    return { sessionId };
}

async function requireAuthLoader() {
    if (!isLoggedIn()) {
        console.error('Not logged in');
        return redirect('/login');
    }
    return null;
}

const goBack = () => window.history.back();

const router = createBrowserRouter([
    {
        path: '/', loader: requireAuthLoader, errorElement: <ErrorHandler />, Component: Home, children: [
            {
                path: '',
                element: <Navigate to="/sessions" />,
            },
            {
                path: '/profile',
                Component: Profile,
                loader: profileLoader,
            },
            {
                path: '/sessions',
                Component: Sessions,
                loader: sessionsLoader,
            },
            {
                path: '/sessions/new',
                //TODO - figure out idiomatic way to cancel this form
                element: <div className="p-4"><SessionForm heading={'New Session'} session={createSession()} onCancel={goBack} /></div>,
                action: updateSessionAction,
            },
            {
                path: '/sessions/:sessionId',
                element: <Session />,
                loader: sessionLoader,
                action: updateSessionAction,
                children: [
                    {
                        path: '/sessions/:sessionId/destroy',
                        action: destroySessionAction
                    },
                    {
                        path: '/sessions/:sessionId/modules/:moduleId/update',
                        action: updateModuleAction
                    },
                    {
                        path: '/sessions/:sessionId/modules/:moduleId/destroy',
                        action: destroyModuleAction
                    },
                    {
                        path: '/sessions/:sessionId/documents/:documentId/update',
                        action: updateDocumentAction
                    },
                    {
                        path: '/sessions/:sessionId/documents/:documentId/destroy',
                        action: destroyDocumentAction
                    },
                    {
                        path: '/sessions/:sessionId/modules/create',
                        action: updateModuleAction,
                    },
                    {
                        path: '/sessions/:sessionId/documents/create',
                        action: updateDocumentAction,
                    },
                ]
            },
            {
                path: '/users',
                Component: Users,
                loader: userLoader,
            },
            {
                path: '/users/new',
                element: <div className="p-4"><UserForm user={createUser()} onCancel={goBack} /></div>,
                action: createUserAction,
            },
            {
                path: '/users/:userId/archive',
                action: archiveUserAction,
            },
            {
                path: '/users/:userId/delete',
                action: deleteUserAction,
            },
            {
                path: '/users/:userId/restore',
                action: restoreUserAction,
            },
            {
                path: '/files',
                Component: Files,
                loader: fileLoader,
            },
            {
                path: '/files/:fileId/delete',
                action: destroyFileAction,
            },
            {
                path: '/notifications',
                Component: Notifications,
                loader: notificationLoader,
            },
        ]
    },
    { path: '/login', Component: Login },
    { path: '/mobile-required', Component: MobileRequired }
], { basename: process.env.PUBLIC_URL });

function App() {
    useCustomTheme();
    return (
        <ErrorBoundary>
            <AuthProvider>
                <Alert />
                {/* <Confirmation /> */}
                <RouterProvider router={router} />
            </AuthProvider>
        </ErrorBoundary>
    );
}

export default App;
