import { Modal } from "flowbite-react";
import { FormEvent, useState } from "react";
import { useSubmit } from "react-router-dom";

export type ConfirmationArgs = {
    action: string;
    message: string;
}

enum ActionType {
    HideDialog,
    ShowDialog,
    Confirm,
    Cancel,
}

type Action = 
    // {
    //     type: ActionType.HideDialog,
    // } |
    {
        type: ActionType.ShowDialog,
        args: ConfirmationArgs,
        target: any,
        submit: (target: any) => void,
    } |
    {
        type: ActionType.Confirm,
    } |
    {
        type: ActionType.Cancel,
    }

interface State {
    isVisible: boolean;
    args?: ConfirmationArgs;
    target?: any;
    submit?: (target: any) => void;
}

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionType.Cancel:
            return {
                isVisible: false,
            };
        case ActionType.Confirm:
            // state.form?.requestSubmit();
            // state.form?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
            if (state.submit) {
                state.submit(state.target);
            }
            return {
                isVisible: false,
            };
        // case ActionType.HideDialog:
        //     return { isVisible: false };
        case ActionType.ShowDialog:
            return {
                isVisible: true,
                args: action.args,
                submit: action.submit,
                target: action.target,
            };
    }
}

let memoryState: State = { isVisible: false };

export function useConfirmation() {
    const [state, setState] = useState(memoryState);
    const submit = useSubmit();
        
    const dispatch = (action: Action) => {
        memoryState = reducer(memoryState, action);
        /* this is wonky looking, but necessary for this to work;
         * TODO - find new npm package for modal confirmation */
        setState(memoryState);
    };

    const confirm = (event: FormEvent<HTMLFormElement>, args: ConfirmationArgs) => {
        event.preventDefault();
        dispatch({ type: ActionType.ShowDialog, target: event.currentTarget, submit, args });
    };

    const confirmNow = (args: ConfirmationArgs, onConfirm: () => void) => {
        dispatch({ type: ActionType.ShowDialog, target: null, submit: onConfirm, args });
    };

    return {
        confirm,
        confirmNow,
        dispatch,
    };
}

export function Confirmation() {
    const { dispatch } = useConfirmation();
    const state = memoryState;

    const handleCancel = () => dispatch({ type: ActionType.Cancel });
    const handleConfirm = () => dispatch({ type: ActionType.Confirm });

    return (
        <Modal popup size="md" show={state.isVisible} onClose={handleCancel}>
            <Modal.Header>
                {state.args?.action}
            </Modal.Header>
            <Modal.Body>
                <p className="mt-1 text-sm text-gray-700">
                    {state.args?.message}
                </p>
                <div className="flex justify-end mt-4">
                    <button className="delete-btn" onClick={handleConfirm}>
                        {state.args?.action}
                    </button>
                    <button
                        className="ml-2 block rounded-lg px-4 py-2 text-gray-700 transition hover:bg-gray-100"
                        onClick={handleCancel}
                    >
                        Cancel
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
