import { useLoaderData, Form, useRevalidator } from 'react-router-dom';
import { toast } from 'react-hot-toast'
import { getCollection, getItem } from "../helpers/api-utils";
import Module, { moduleFormFields } from './Module';
import SessionForm from './SessionForm';
import Document, { documentFormFields } from './Document';
import { Module as ModuleModel } from '../models/module';
import { useState } from 'react';
import { Session as SessionModel } from '../models/session';
import { Document as DocumentModel } from '../models/document';
import Accordion from './Accordion';
import { fetchProtectedData } from '../helpers/http-utils';
import invariant from 'tiny-invariant';
import { createModule } from '../actions';
import { createDocument } from '../actions/document-actions';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

export async function sessionLoader(args: any) {
  invariant(args.params.sessionId, 'Session ID is required');

  const sessionId = Number(args.params.sessionId);
  const [session, modules, documents] = await Promise.all([
    getItem({ resource: 'sessions', id: sessionId }),
    getCollection(`sessions/${sessionId}/modules`),
    getCollection(`sessions/${sessionId}/documents`),
  ]);
  return { session, modules, documents };
}

export default function Session() {
  const { session, modules, documents } = useLoaderData() as { session: SessionModel, modules: ModuleModel[], documents: DocumentModel[] };
  const [activeTab, setActiveTab] = useState<'modules' | 'documents'>('modules');
  const [showSessionForm, setShowSessionForm] = useState(false);
  const [showModuleForm, setShowModuleForm] = useState(false);
  const [showDocForm, setShowDocForm] = useState(false);
  const revalidator = useRevalidator();

  const reorderMoudles = async (order: number[]) => {
    const { statusCode, data } = await fetchProtectedData({ url: '/modules/sort-order', method: 'PUT', body: { order } });

    if (statusCode !== 200) {
      toast.error(data.message?.message || 'Unable to re-order modules');
    }

    revalidator.revalidate();
  };

  const reorderDocs = async (order: number[]) => {
    const { statusCode, data } = await fetchProtectedData({ url: '/documents/sort-order', method: 'PUT', body: { order } });

    if (statusCode !== 200) {
      toast.error(data.message?.message || 'Unable to re-order modules');
    }

    revalidator.revalidate();
  };

  const getTabClass = (tabName: string): string => activeTab === tabName ?
    'tab-button active' :
    'tab-button';

  const closeForm = () => setShowSessionForm(false);

  return (
    <div className="p-4 overflow-y-auto max-h-screen">
      {showSessionForm ?
        <SessionForm heading={'Edit Session'} session={session} onSave={closeForm} onCancel={closeForm} /> :
        <div className='rounded-lg bg-gold'>
          <div className='flex justify-between items-center p-4'>
            <div className='flex items-center'>
              <Link className='text-white text-3xl' to="/sessions"><FontAwesomeIcon icon={faAngleLeft} /></Link>
              <h1 className='text-white text-3xl font-bold ml-4'>
                {session.name}: Session Content
              </h1>
            </div>
            <button type='button' className='edit-btn' onClick={() => setShowSessionForm(true)}>
              Edit Session
            </button>
          </div>
          <div className='flex justify-end px-4'>
            <Link to={`/notifications?sessionId=${session.id}`} className='add-btn'>Notify Users About This Session</Link>
          </div>
          <nav className="flex border-b border-gray-100 text-sm font-medium">
            <button className={getTabClass('modules')} onClick={() => setActiveTab('modules')}>
              Modules
            </button>
            <button className={getTabClass('documents')} onClick={() => setActiveTab('documents')}>
              Documents
            </button>
          </nav>
        </div>}
      {activeTab === 'modules' &&
        <>
          {!showSessionForm &&
            <button type='button' onClick={() => setShowModuleForm(true)} className="my-4 w-full add-btn">
              Add New Module
            </button>}
          {showModuleForm && <Form method='post' action={'modules/create'} onSubmit={() => setShowModuleForm(false)}>
            {moduleFormFields(createModule())}

            <button type="submit" className='my-4 save-btn'>
              Create Module
            </button>
          </Form>}
          <Accordion
            collection={modules}
            onRearrange={reorderMoudles}
            renderContent={item => <Module module={item as ModuleModel} />}
          />
        </>
      }
      {activeTab === 'documents' &&
        <>
          {!showSessionForm &&
            <button type="button" onClick={() => setShowDocForm(true)} className="my-4 w-full add-btn">
              Add New Document
            </button>}
          {showDocForm && <Form method='post' action={'documents/create'} onSubmit={() => setShowDocForm(false)}>
            {documentFormFields(createDocument())}
            <button type="submit" className='my-4 save-btn'>
              Create Document
            </button>
          </Form>}
          <Accordion
            collection={documents}
            onRearrange={reorderDocs}
            renderContent={item => <Document document={item as DocumentModel} />}
          />
        </>
      }
    </div>
  );
}