import { useLoaderData } from "react-router";
import { getItem } from "../helpers/api-utils";
import { UserProfile } from "../models/user";

export async function profileLoader() {
    const data = await getItem({ url: 'auth/profile' });
    return { profile: data.user };
}

export function Profile() {
    const { profile } = useLoaderData() as { profile: UserProfile };

    return (
        <section>
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
                <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                    <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                        <img alt="" src={profile.profileImage} className="absolute inset-0 h-full w-full object-cover" />
                    </div>

                    <div className="lg:py-24">
                        <h2 className="text-3xl font-bold sm:text-4xl">{profile.name}</h2>

                        <p className="mt-4 text-gray-600"><span className="font-bold">Bio:</span> {profile.bio}</p>
                    </div>
                </div>
            </div>
        </section>
    );
}