import { Table } from "flowbite-react";
import { ApiCollection, getCollection } from "../helpers/api-utils";
import { Form, useLoaderData, useNavigate } from "react-router-dom";
import { UserCollectionItem } from "../models/user";
import { useState } from "react";
import { Role } from "../models/role";
import { Confirmation, useConfirmation } from "../Confirm";
import { Link } from "react-router-dom";
import ActiveUserRow from './ActiveUserRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { FetchArgs } from "../models/fetch-args";
import { buildSearch } from "../helpers/http-utils";
import { SearchInput } from "./inputs";

export type UserDisplayFields = { isAdmin: boolean, hasSubscription: boolean, subscriptionTier: string };

export async function userLoader({ request }: any) {
  const url = new URL(request.url);
  const args = Object.fromEntries(url.searchParams) as FetchArgs;

  const [activeUserData, archivedUserData, roles] = await Promise.all([
    getCollection('users', { perPage: 500, ...args }), //default 'take' is 10
    getCollection('users', { perPage: 500, ...args, isActive: false }),
    getCollection('roles'),
  ]) as [UserCollectionItem[], UserCollectionItem[], Role[]];

  const activeUsers = activeUserData.map(u => {
    //active roles sorted by updatedAt in descending order
    // const roles = u.roles
    //     .filter(r => r.isActive)
    //     .sort((r1, r2) => new Date(r2.updatedAt).valueOf() - new Date(r1.updatedAt).valueOf());

    return {
      ...u,
      isAdmin: u.roles.some(r => r.role.code === 'ADMIN'),
      hasSubscription: roles.length > 0,
      subscriptionTier: u.roles[0]?.role?.code,
    } as UserCollectionItem & UserDisplayFields;
  });

  const archivedUsers = archivedUserData.map(u => ({
    ...u,
    updatedAt: new Date(u.updatedAt),
  }));

  return { activeUsers, archivedUsers, roles, args };
}

export default function Users() {
  const { activeUsers, archivedUsers, args } = useLoaderData() as
    { activeUsers: (UserCollectionItem & UserDisplayFields)[], archivedUsers: (UserCollectionItem & UserDisplayFields)[], roles: Role[], args: FetchArgs };
  const [editUserId, setEditUserId] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);
  const { confirm } = useConfirmation();
  const navigate = useNavigate();
  const { keyword, sortOrder, sortBy } = args;

  const activeUserRows = activeUsers.map(user => (
    <ActiveUserRow
      key={user.id}
      user={user}
      isEditing={editUserId === user.id}
      isUpdating={isUpdating}
      onSetEditUserId={setEditUserId}
      onSetIsUpdating={setIsUpdating}
    />
  ));

  const changeSort = (field: string) => {
    const newSortOrder = field === sortBy ?
      sortOrder === 'asc' ?
        'desc' :
        'asc'
      : 'asc';
    const newArgs = {
      ...args,
      sortBy: field,
      sortOrder: newSortOrder
    }

    navigate({ pathname: '/users', search: buildSearch(newArgs) });
  };

  const sortButton = (field: string, title: string) => (
    <span style={{ cursor: 'pointer' }} onClick={() => changeSort(field)}>
      {sortBy === field && <FontAwesomeIcon icon={faCaretLeft} className={"w-3 h-3 " + (sortOrder === 'asc' ? 'rotate-90' : '-rotate-90')} />}
      {title}
    </span>
  );

  const activeUsersTable = (
    <Table hoverable={true} className="mt-2">
      <Table.Head>
        <Table.HeadCell>{sortButton('isAdmin', 'Is Admin?')}</Table.HeadCell>
        <Table.HeadCell>{sortButton('subscriptionTier', 'Tier')}</Table.HeadCell>
        <Table.HeadCell>{sortButton('sendJournalReminders', 'Journal Reminders?')}</Table.HeadCell>
        <Table.HeadCell>{sortButton('name', 'Name')}</Table.HeadCell>
        <Table.HeadCell>{sortButton('email', 'Email')}</Table.HeadCell>
        <Table.HeadCell>{sortButton('memberSince', 'Member Since')}</Table.HeadCell>
        <Table.HeadCell>&nbsp;</Table.HeadCell>
      </Table.Head>
      <Table.Body>
        {activeUserRows}
      </Table.Body>
    </Table>
  );

  const archivedUserRows = (
    archivedUsers.length ?
      archivedUsers.map(user =>
        <Table.Row key={user.id}>
          <Table.Cell>{user.name}</Table.Cell>
          <Table.Cell>{user.email}</Table.Cell>
          <Table.Cell>{user.updatedAt.toLocaleString()}</Table.Cell>
          <Table.Cell>
            <Form
              method="post"
              className="flex justify-end"
              action={`${user.id}/restore`}
              onSubmit={event => confirm(event, { action: 'Restore User', message: 'Are you sure you want to restore this user?' })}
            >
              <button type="submit" disabled={isUpdating} className="edit-btn">
                Restore User
              </button>
            </Form>
          </Table.Cell>
        </Table.Row>
      ) :
      <Table.Row>
        <Table.Cell colSpan={4} className="text-red-600 text-center">No data to display</Table.Cell>
      </Table.Row>
  );

  const archivedUsersTable = (
    <Table hoverable={true} className="mt-2">
      <Table.Head>
        <Table.HeadCell>{sortButton('name', 'Name')}</Table.HeadCell>
        <Table.HeadCell>{sortButton('email', 'Email')}</Table.HeadCell>
        <Table.HeadCell>{sortButton('updatedAt', 'Archived Date')}</Table.HeadCell>
        <Table.HeadCell>&nbsp;</Table.HeadCell>
      </Table.Head>
      <Table.Body>
        {archivedUserRows}
      </Table.Body>
    </Table>
  );

  const [activeTab, setActiveTab] = useState<'active-users' | 'archived-users'>('active-users');

  const getTabClass = (tabName: string): string => activeTab === tabName ?
    'tab-button active' :
    'tab-button';

  const searchUsers = (search: string) => {
    const newArgs = {
      ...args,
      keyword: search
    };
    console.log('SEARCH', search);
    navigate({ pathname: '/users', search: buildSearch(newArgs) });
  };

  return (
    <div className="p-4 overflow-y-auto max-h-screen">
      <Confirmation />
      <div className="flex justify-between">
        <h1 className='text-3xl'>Users</h1>
        <div className="flex">
          <Link to="/notifications" className="save-btn mr-4">
            Send Push Notification
          </Link>
          <Link to="/notifications?journalReminder=true" className="save-btn mr-4">
            Send Journal Reminder
          </Link>
          <Link to="/users/new" className="add-btn">
            New User
          </Link>
        </div>
      </div>
      <nav className="flex border-b border-gray-100 mt-4 text-sm font-medium justify-between">
        <div>
          <button className={getTabClass('active-users')} onClick={() => setActiveTab('active-users')}>
            Active Users
          </button>
          <button className={getTabClass('archived-users')} onClick={() => setActiveTab('archived-users')}>
            Archived Users
          </button>
        </div>
        <div>
          <SearchInput value={keyword ?? ''} label="Search users" onSubmit={search => searchUsers(search)} />
        </div>
      </nav>
      {activeTab === 'active-users' && activeUsersTable}
      {activeTab === 'archived-users' && archivedUsersTable}
    </div>
  );
}