import { Form } from 'react-router-dom';
import { DateInput, TextAreaInput, TextInput } from './inputs';
import { Confirmation, useConfirmation } from '../Confirm';
import { Module as ModuleModel } from '../models/module';
import { Link } from 'react-router-dom';

export function moduleFormFields(module: ModuleModel) {
    return (
        <>
            <TextInput name='name' value={module.name} />
            <TextInput name='description' value={module.description} placeholder='description (shows in white text)' />
            <TextInput name='tab' value={module.tab} placeholder='tab (shows in gray text)' />
            <TextAreaInput name='information' value={module.information ?? ''} placeholder='information (links, transcript, etc.)' />
            <TextInput name='videoUrl' value={module.videoUrl ?? ''} />
            <TextInput name='thumbnailUrl' value={module.thumbnailUrl ?? ''} />
            <TextInput name='time' value={module.time ?? ''} />
            <DateInput name="dueDate" value={module.dueDate} />
            <TextInput name="visibleFrom" type='number' value={module.visibleFrom?.toString() || ''} placeholder="Visible From (days)" />
            <span className="text-xs text-gray-600">If the value is empty, the module will be available to everyone</span>
            <input type="hidden" name='code' value={module.code} />
        </>
    );
}

export default function Module(props: { module: ModuleModel }) {
    const module = props.module;

    const deleteFormId = `mod-${module.id}-del-from`;
    const { confirm } = useConfirmation();

    return (
        <>
            <Confirmation />

            <div className='flex items-center justify-between'>
                <h2 className='text-xl'>{module.name}</h2>
                <Link to={`/notifications?sessionId=${module.sessionId}&moduleId=${module.id}`} className='add-btn'>Notify Users About This Module</Link>
            </div>

            <Form method='post' action={`modules/${module.id}/update`}>
                {moduleFormFields(module)}

                <div className='pt-4 flex justify-end'>
                    <button form={deleteFormId} type="submit" className='w-48 mr-4 delete-btn'>
                        Delete Module
                    </button>
                    <button type="submit" className='w-48 save-btn'>
                        Save Module
                    </button>
                </div>
            </Form>

            <Form
                id={deleteFormId}
                className='my-4'
                method="post"
                action={`modules/${module.id}/destroy`}
                onSubmit={event => confirm(event, { action: 'Delete Module', message: 'Are you sure you want to delete this module?' })}>
            </Form>
        </>
    );
}