import { toast } from "react-hot-toast";
import { deleteItem } from "../helpers/api-utils";
import { redirect } from "react-router-dom";

export async function destroyFileAction(args: any) {
    const fileId = args.params.fileId;
    const result = await deleteItem('files', fileId);

    result.success ?
        toast.success('File deleted') :
        toast.error(result.message || 'Failed to delete');

    return redirect('/files');
}