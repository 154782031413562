import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ChangeEventHandler, useState } from "react";
import { SearchIcon } from "./icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

dayjs.extend(utc);

type BaseInputProps = { name: string, value: string, onChange?: ChangeEventHandler<HTMLInputElement> };
type TextInputProps = BaseInputProps & { type?: string, placeholder?: string };

export function TextInput(props: TextInputProps) {
    return (
        <label
            className="mt-4 relative block overflow-hidden rounded-md border border-gray-200 px-3 pt-3 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600"
            htmlFor={props.name}
        >
            <input
                type={props.type ?? 'text'}
                name={props.name}
                placeholder={props.placeholder}
                className="peer h-8 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                defaultValue={props.value}
                onChange={props.onChange}
            />
            <span
                className="absolute start-3 top-3 -translate-y-1/2 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-3 peer-focus:text-xs"
            >
                {props.placeholder ?? props.name}
            </span>
        </label>
    );
}

export function TextAreaInput(props: TextInputProps & { rows?: number, cols?: number, onChange?: ChangeEventHandler<HTMLTextAreaElement> }) {
    return (
        <label
            className="mt-4 relative block overflow-hidden rounded-md border border-gray-200 px-3 pt-3 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600"
            htmlFor={props.name}
        >
            <textarea
                rows={props.rows}
                cols={props.cols}
                name={props.name}
                placeholder={props.placeholder}
                className="peer w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                defaultValue={props.value}
                onChange={props.onChange}
            />
            <span
                className="absolute start-3 top-3 -translate-y-1/2 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-3 peer-focus:text-xs"
            >
                {props.placeholder ?? props.name}
            </span>
        </label>
    );
}

export function DateInput(props: BaseInputProps) {
    return (
        <label
            className="mt-4 relative block overflow-hidden rounded-md border border-gray-200 px-3 pt-3 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600"
            htmlFor={props.name}
        >
            <input
                type="date"
                name={props.name}
                placeholder={props.name}
                className="peer h-8 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                defaultValue={dayjs(props.value).utc().format('YYYY-MM-DD')}
                onChange={props.onChange}
            />
            <span
                className="absolute start-3 top-3 -translate-y-1/2 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-3 peer-focus:text-xs"
            >
                {props.name}
            </span>
        </label>
    );
}

export function SearchInput(props: { value: string, label: string, onSubmit: (search: string) => void }) {
    const [search, setSearch] = useState(props.value);
    const [isSearchActive, setIsSearchActive] = useState(props.value.length > 0);

    const clearSearch = () => {
        setSearch('');
        props.onSubmit('');
        setIsSearchActive(false);
    }

    const doSearch = () => {
        if (search.length) {
            props.onSubmit(search ?? '');
            setIsSearchActive(true);
        }
    };

    const onClick = () => {
        if (!isSearchActive) {
            doSearch();
        } else {
            clearSearch();
        }
    };

    const onKeyUp = (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            doSearch();
        }
    };

    return (
        <div className="relative">
            <label htmlFor="Search" className="sr-only">{props.label}</label>

            <input
                type="text"
                id="Search"
                placeholder={props.label}
                className="w-full rounded-md border-gray-200 py-2.5 pe-10 shadow-sm sm:text-sm"
                value={search}
                onChange={e => setSearch(e.target.value)}
                onBlur={doSearch}
                onKeyUp={onKeyUp}
            />

            <span className="absolute inset-y-0 end-0 grid w-10 place-content-center">
                <button type="button" className="text-gray-600 hover:text-gray-700" onClick={onClick}>
                <span className="sr-only">{props.label}</span>
                {isSearchActive ? <FontAwesomeIcon icon={faCircleXmark} /> : <SearchIcon />}
                </button>
            </span>
        </div>
    )
}