import { Toaster, resolveValue } from 'react-hot-toast';
import { capitalize } from '../helpers/string-utils';
import { ErrorIcon, SuccessIcon } from './icons';

const Alert = () => {
    return (
        <Toaster
            position='top-center'
            toastOptions={{
                duration: 3000,
            }}
        >
        {t => (
            <div
                role="alert"
                className="rounded-xl border border-gray-100 bg-white p-4 shadow-xl absolute t-4"
            >
                <div className="flex items-start gap-4">
                    {t.type === 'error' ? <ErrorIcon /> : <SuccessIcon />}

                    <div className="flex-1">
                        <strong className="block font-medium text-gray-900"> {capitalize(t.type)} </strong>
                        <p className="mt-1 text-sm text-gray-700">
                            {resolveValue(t.message, t)}
                        </p>
                    </div>

                    {/* <button className="text-gray-500 transition hover:text-gray-600" onClick={() => t.onClose()}>
                    <span className="sr-only">Dismiss popup</span>

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-6 w-6"
                    >
                        <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                    </button> */}
                </div>
            </div>)
        }
        </Toaster>
    );
}

export default Alert;