import { ActionFunctionArgs, redirect } from "react-router-dom";
import { Session } from "../models/session";
import { createItem, deleteItem, updateItem } from "../helpers/api-utils";
import { toast } from "react-hot-toast";
import invariant from "tiny-invariant";

export function createSession() {
    const session = {
        name: 'New session',
        description: '',
        type: 'Course',
        code: '99',
        price: 'n/a'
    } as Session;
    return session;
}

export async function updateSessionAction(args: ActionFunctionArgs) {
    const formData = await args.request.formData();
    const model = Object.fromEntries(formData);
    let sessionId = args.params.sessionId;

    const isNew = sessionId == null;
    const result = isNew ?
        await createItem('sessions', model) :
        await updateItem({ resource: 'sessions', id: Number(sessionId), changes: model });
    
    if (result.success) {
        isNew ?
            toast.success('Created new session') :
            toast.success('Session saved');
        
        sessionId = result.data.id;
        return redirect(`/sessions/${sessionId}`);
    }

    toast.error(result.message || 'Failed to save');

    return isNew ?
        redirect('/sessions/new') :
        redirect(`/sessions/${sessionId}`);
}

export async function destroySessionAction(args: ActionFunctionArgs) {
    invariant(args.params.sessionId, 'Session ID is required');

    const sessionId = Number(args.params.sessionId);
    const result = await deleteItem('sessions', sessionId);

    if (result.success) {
        toast.success('Session deleted');
        return redirect('/sessions');
    }
        
    toast.error(result.message || 'Failed to delete');
    return redirect(`/sessions/${sessionId}`);
}