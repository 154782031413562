import { Form } from 'react-router-dom';
import { TextInput } from './inputs';
import { Confirmation, useConfirmation } from '../Confirm';
import { Session } from '../models/session';
import { ToggleSwitch } from 'flowbite-react';
import { useState } from 'react';

export default function SessionForm(props: { session: Session, heading: string, onSave?: () => void, onCancel: () => void }) {
    const { confirm } = useConfirmation();
    const [session, setSession] = useState(props.session);
    const isNew = session.id == null;

    const handleFeaturedChange = (checked: boolean) => {
        setSession({ ...session, isFeatured: checked });
    };

    return (
        <>
            <Confirmation />

            <Form method='post' onSubmit={() => props.onSave && props.onSave()}>
                <div className='m-2 flex justify-between'> 
                    <h1 className='text-3xl'>{props.heading}</h1>
                    <div className='flex'>
                        <button type="submit" className='mr-4 save-btn'>Save</button>
                        <button type="button" className='edit-btn' onClick={() => props.onCancel()}>
                            Cancel
                        </button>
                    </div>
                </div>
                <div className='pt-4'>
                    <TextInput name='name' value={session.name} />
                    <TextInput name='description' value={session.description} />
                    <TextInput type='number' placeholder='Visible From (days)' name='visibleFrom' value={session.visibleFrom?.toString() || ''} />
                    <span className="text-xs text-gray-600 ml-2">If the value is empty, the session will be available to everyone</span>
                    <TextInput name='type' value={session.type} />
                    <TextInput placeholder='code (sort order)' name='code' value={session.code} />
                    <ToggleSwitch className='mt-4' checked={session.isFeatured} label='Feature on Dashboard?' onChange={handleFeaturedChange} />

                    <input type="hidden" name='isFeatured' value={session.isFeatured?.toString()} />
                    <input type="hidden" name='price' value={session.price} />
                </div>
            </Form>

            {!isNew &&
            <Form
                className='my-4'
                method="post"
                action="destroy"
                onSubmit={event => confirm(event, { action: 'Delete Session', message: 'Are you sure you want to delete this session?' })}
            >
                <button type="submit" className='delete-btn'>Delete Session</button>
            </Form>}
        </>
    );
}