import { Progress, Spinner, Table } from "flowbite-react";
import { getCollection, uploadFile } from "../helpers/api-utils";
import { Form, useLoaderData, useRevalidator } from "react-router-dom";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { CloudFile } from "../models/cloud-file";
import { useRef, useState } from "react";
import { Confirmation, useConfirmation } from "../Confirm";

export async function fileLoader() {
    const prefix = 'app/';
    const data = await getCollection('files', { prefix }) as any[];
    const files = data.map(f => ({
        ...f,
        key: f.key.replace(prefix, ''),
        createdAt: new Date(f.createdAt),
    })) as CloudFile[];

    return { files };
}

export default function Files() {
    const { files } = useLoaderData() as { files: CloudFile[] };
    const fileRef = useRef<HTMLInputElement>(null);
    const revalidator = useRevalidator();
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const { confirm } = useConfirmation();

    const copyUrl = (url: string) => {
        navigator.clipboard.writeText(url);
        toast.success('URL copied to clipboard');
    };

    const upload = async (e: any) => {
        setIsUploading(true);

        const onProgress = (percent: number) => {
            setUploadProgress(percent);
        };
        
        const file = e.target.files[0];
        const result = await uploadFile('app', file, onProgress);

        setIsUploading(false);
        
        if (result.success) {
            toast.success('File saved');
        } else {
            toast.error(result.message || 'error');
        }
        
        revalidator.revalidate();
    };

    const tableRows = files.map(file => (
        <Table.Row key={file.id}>
            <Table.Cell>{file.key}</Table.Cell>
            <Table.Cell>{file.createdAt.toLocaleString()}</Table.Cell>
            <Table.Cell>{file.url}</Table.Cell>
            <Table.Cell>
                <button type="button" className="inline-flex items-center add-btn" onClick={() => copyUrl(file.url)}>
                    <FontAwesomeIcon className="mr-2" icon={faClipboard} />
                    Copy
                </button>
            </Table.Cell>
            <Table.Cell>
                <Form 
                    method="post" 
                    action={`/files/${file.id}/delete`} 
                    onSubmit={event => confirm(event, { action: 'Delete File', message: 'Are you sure you want to delete this file?' })}
                >
                    <button type="submit" className="delete-btn">
                        Delete
                    </button>
                </Form>
            </Table.Cell>
        </Table.Row>
    ));

    return (
        <div className="p-4 overflow-y-auto max-h-screen">
            <Confirmation />
            <div className='my-4 flex justify-between items-center'>
                <h1 className='text-3xl'>Files</h1>
                {isUploading && <div className="flex-1 mx-4"><Progress color="dark" progress={uploadProgress} /></div>}
                <input name="newFile" type="file" ref={fileRef} className="hidden" multiple={false} onChange={upload} />
                <button 
                    type="button"
                    className="save-btn"
                    onClick={() => fileRef.current?.click()}
                    disabled={isUploading}
                >
                    {isUploading && <Spinner size="sm" className="mr-2" />}
                    {isUploading ? "Loading..." : "Upload File"}
                </button>
            </div>
            <Table hoverable={true} className="mt-2">
                <Table.Head>
                    <Table.HeadCell>Name</Table.HeadCell>
                    <Table.HeadCell>Uploaded On</Table.HeadCell>
                    <Table.HeadCell>URL</Table.HeadCell>
                    <Table.HeadCell>&nbsp;</Table.HeadCell>
                    <Table.HeadCell>&nbsp;</Table.HeadCell>
                </Table.Head>
                <Table.Body>
                    {tableRows}
                </Table.Body>
            </Table>
        </div>
    );
}