import toast from "react-hot-toast";
import { getCredentials, requireToken, storeCredentials } from "../hooks/use-auth";
import { redirect } from "react-router-dom";
import invariant from "tiny-invariant";

const BASE_URL = process.env.REACT_APP_API_URL;

type ObjectType = {
  [key: string]: string | number | boolean | Blob | number[];
};

interface IFetch {
  url: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  body?: ObjectType;
  headers?: HeadersInit | undefined;
  params?: ObjectType;
  token?: string;
  isFormData?: boolean;
}

export type ProgressCallback = (percentage: number) => void;

export function buildSearch(params: any) {
  let search = '';
  Object.keys(params).forEach((key, index) => {
    if (params[key] !== undefined) {
      if (index === 0) {
        // @ts-ignore
        search += `?${key}=${encodeURIComponent(params[key])}`;
      } else {
        // @ts-ignore
        search += `&${key}=${encodeURIComponent(params[key])}`;
      }
    }
  });
  return search;
}

const joinUrl = (segment1: string, segment2: string): string =>
  (segment1.endsWith('/') ? segment1 : segment1 + '/')
    + (segment2.startsWith('/') ? segment2.substring(1) : segment2);

export async function fetchData({
  headers = {},
  method = 'GET',
  params = {},
  url,
  body = {},
  token,
  isFormData = false,
}: IFetch): Promise<{
  data: any;
  statusCode: number;
  response: Response;
}> {
  invariant(BASE_URL, 'Base URL is not set!');

  const req_headers: HeadersInit = {
    Accept: 'application/json',
  };

  if (!isFormData) {
    req_headers['Content-Type'] = 'application/json';
  }

  if (token) {
    req_headers.Authorization = `Bearer ${token}`;
  }

  const requestInfo: RequestInit = {
    method: method || 'GET',
    headers: { ...req_headers, ...headers },
  };

  if (body && (Object.keys(body).length || body instanceof File)) {
    if (isFormData) {
      const formData = new FormData();
      if (body instanceof File) {
        formData.append('file', body);
      }
      else {
        Object.keys(body).forEach(key => {
          if (body[key] !== undefined) {
            // @ts-ignore
            formData.append(key, body[key]);
          }
        });
      }
      requestInfo.body = formData;
    } else {
      requestInfo.body = JSON.stringify(body);
    }
  }

  const response = await fetch(joinUrl(BASE_URL, url) + buildSearch(params), requestInfo);
  const data = await response.json();
  const statusCode = response.status;

  return { data, statusCode, response };
}

export async function fetchProtectedData(fetchArgs: Omit<IFetch, 'token'>) {
  const { data, statusCode } = await fetchData({ ...fetchArgs, token: requireToken() });

  if ([401, 403].includes(statusCode)) {
    const { accessToken, refreshToken } = getCredentials();
    if (refreshToken) {
      const body = { accessToken, refreshToken };
      const { statusCode, data: credentials } = await fetchData({ method: 'POST', url: '/auth/refresh', body });
      if (statusCode === 200 && credentials.accessToken) {
        console.log('Refreshed access token!');
        storeCredentials(credentials);
        return fetchData({ ...fetchArgs, token: credentials.accessToken });
      }
    }

    toast.error('Your session has expired. Please log in again.');
    throw redirect('/login');
  }

  return { statusCode, data };
}

// export function uploadData({
//     headers = {},
//     method = 'POST',
//     params = {},
//     url,
//     body = {},
//     token,
//     isFormData = true,
//     progressCallback = undefined,
// }: IFetch & { progressCallback?: ProgressCallback }): Promise<{
//     data: any;
//     statusCode: number;
// }> {
//     return new Promise((resolve, reject) => {
//         const xhr = new XMLHttpRequest();

//         Object.keys(params).forEach(key => {
//             if (key === 'for') {
//             } else {
//                 if (!params[key] || params[key] === 'false') {
//                     delete params[key];
//                 }
//             }
//         });

//         Object.keys(params).forEach((key, index) => {
//             if (params[key]) {
//                 if (index === 0) {
//                     // @ts-ignore
//                     url += `?${key}=${encodeURIComponent(params[key])}`;
//                 } else {
//                     // @ts-ignore
//                     url += `&${key}=${encodeURIComponent(params[key])}`;
//                 }
//             }
//         });

//         const onComplete = () => {
//             resolve({ data: xhr.response, statusCode: xhr.status });
//         };

//         const onError = () => {
//             reject({ data: xhr.statusText, statusCode: xhr.status });
//         }

//         xhr.addEventListener('load', onComplete);
//         xhr.addEventListener('error', onError);

//         if (progressCallback) {
//             const onProgress = (event: any) => {
//                 const percentComplete = event.lengthComputable ?
//                     (event.loaded / event.total) * 100 :
//                     100;
//                 progressCallback(percentComplete);
//             }
//             xhr.addEventListener('progress', onProgress);
//         }

//         xhr.open(method, BASE_URL + url, true);

//         xhr.setRequestHeader('Accept', 'application/json');
//         if (token) {
//             xhr.setRequestHeader('Authorization', `Bearer ${token}`);
//         }

//         for (let key of Object.keys(headers)) {
//             // @ts-ignore
//             const val = headers[key];
//             if (val) {
//                 // @ts-ignore
//                 xhr.setRequestHeader(key, headers[key]);
//             }
//         }

//         let reqBody: XMLHttpRequestBodyInit | null = null;
//         if (body && (Object.keys(body).length || body instanceof File)) {
//             if (isFormData) {
//                 const formData = new FormData();
//                 if (body instanceof File) {
//                     formData.append('file', body);
//                 }
//                 else {
//                     Object.keys(body).forEach(key => {
//                         if (body[key] !== undefined) {
//                             // @ts-ignore
//                             formData.append(key, body[key]);
//                         }
//                     });
//                 }
//                 reqBody = formData;
//             } else {
//                 reqBody = JSON.stringify(body);
//             }
//         }

//         xhr.send(reqBody);
//     });
// }